// --------------------------------------- Project List
export const ProjectList = [
  {
    img: "/projects/mouse-automator.jpg",
    title: "Mouse Automator",
    description:
      "A lightweight, user-friendly multiplatform desktop application designed to automate mouse clicks with ease. Specialized for running with selected applications.",
    tech_stack: ["Electron.js", "React.js", "TypeScript", "TailwindCSS"],
    github_url: "https://github.com/cch01/mouse-automator",
    demo_url: "",
  },
  {
    img: "/projects/ontario_diner.png",
    title: "Ontario Diner",
    description:
      "A sleek and intuitive web-based application that helps you calculate taxes, discounts, tips, and split the bill. \nPWA supported!!",
    tech_stack: ["React", "TailwindCSS", "React Hook Form", "PWA"],
    github_url: "https://github.com/cch01/ontario-diner",
    demo_url: "https://diner.cch-4679.ca/",
  },
];

// --------------------------------------- Skills

export const stackList = [
  {
    img: "/devicons/html5-original.svg",
    name: "HTML",
  },
  {
    img: "/devicons/css3-original.svg",
    name: "CSS",
  },
  {
    img: "/devicons/javascript-original.svg",
    name: "JavaScript",
  },
  {
    img: "/devicons/typescript-original.svg",
    name: "TypeScript",
  },
  {
    img: "/devicons/flutter-original.svg",
    name: "Flutter",
  },
  {
    img: "/devicons/react-original.svg",
    name: "ReactJS",
  },
  {
    img: "/devicons/react-original.svg",
    name: "React Native",
  },
  {
    img: "/devicons/nextjs-original.svg",
    name: "NextJs",
  },
  {
    img: "/devicons/tailwindcss-original.svg",
    name: "TailwindCSS",
  },
  {
    img: "/devicons/materialui-original.svg",
    name: "Material UI",
  },
  {
    img: "/devicons/bootstrap-original.svg",
    name: "Bootstrap",
  },
  {
    img: "/devicons/jquery-original-wordmark.svg",
    name: "jQuery",
  },
  {
    img: "/devicons/nodejs-original.svg",
    name: "NodeJS",
  },
  {
    img: "/devicons/graphql-plain-wordmark.svg",
    name: "GraphQL",
  },
  {
    img: "/devicons/kubernetes-original.svg",
    name: "Kubernetes",
  },
  {
    img: "/devicons/express-original.svg",
    name: "Docker",
  },
  {
    img: "/devicons/nestjs-original.svg",
    name: "NestJs",
  },
  {
    img: "/devicons/express-original.svg",
    name: "ExpressJs",
  },
  {
    img: "/devicons/postgresql-original.svg",
    name: "Postgresql",
  },
  {
    img: "/devicons/redis-original.svg",
    name: "Redis",
  },
  {
    img: "/devicons/mongodb-original.svg",
    name: "MongoDB",
  },
  {
    img: "/devicons/python-original.svg",
    name: "Python",
  },
  {
    img: "/devicons/flask-original.svg",
    name: "Flask",
  },

  {
    img: "/devicons/heroku-original.svg",
    name: "Heroku",
  },

  {
    img: "/devicons/amazonwebservices-original-wordmark.svg",
    name: "AWS",
  },

  {
    img: "/devicons/vercel-original.svg",
    name: "Vercel",
  },

  {
    img: "/devicons/netlify-original.svg",
    name: "Netlify",
  },
  {
    img: "/devicons/jest-plain.svg",
    name: "Jest",
  },
  {
    img: "/devicons/cypressio-original.svg",
    name: "Cypress",
  },
  {
    img: "/devicons/git-original.svg",
    name: "Git",
  },
];
